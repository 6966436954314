<template>
	<div>
		<div>
			<el-button @click="toShow" type="primary" size="mini">添加提醒</el-button>
		</div>
		<el-table :data="list">
			<el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
			<el-table-column label="提醒时间" prop="tip_time"></el-table-column>
			<el-table-column label="是否提醒">
				<template slot-scope="scope">
					<el-tag :type="scope.row.tip_state === 1 ? 'success' : 'danger'" disable-transitions>
						{{ scope.row.tip_state === 1 ? '已提醒' : '待提醒' }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="状态" prop="state">
				<template slot-scope="scope">
					<el-tag :type="scope.row.state === 1 ? 'success' : 'danger'" disable-transitions>
						{{ scope.row.state === 1 ? '可用' : '禁用' }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="添加时间" prop="create_time"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button @click="toEdit(scope.row.id)" type="text">编辑</el-button>
					<el-button @click="toDel(scope.row.id)" type="text" class="orange">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog class="drag-dialog" title="添加提醒" :visible.sync="show" v-if="show" :close-on-click-modal="false" append-to-body @closed="onClosed">
			<el-form :model="addForm" :rules="rules" label-width="100px" ref="form">
				<el-form-item label="提醒时间" prop="tip_time">
					<push-time v-model="addForm.tip_time" style="width: 200px;"></push-time>
				</el-form-item>
				<el-form-item label="状态">
					<el-switch
						v-model="addForm.state"
						active-color="#13ce66"
						:active-value="1"
						:inactive-value="0"
						active-text="启用"
						inactive-text="禁用">
					</el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="toClose">关闭</el-button>
				<el-button type="primary" @click="toSubmit">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "list",
	data() {
		return {
			list:[],
			totalCount:0,
			totalPage:1,

			show:false,

			searchForm:{
				page:1,
				pageSize:10,
				orderBy:'tip_time',
				sortBy:'desc',
				pid:''
			},

			addForm:{
				id:'',
				pid:'',
				tip_time:'',
				state:1
			},

			rules:{
				tip_time:[{required:true,message:'提醒时间必选'}]
			}
		}
	},
	props:{
		datas: {
			type: [Array,Object],
			default(){
				return {}
			}
		},
	},
	created() {
		console.log(this.datas)
		this.searchForm.pid = this.datas.id ? this.datas.id : ''
		this.addForm.pid = this.datas.id ? this.datas.id : ''
	},
	mounted() {
		if(this.searchForm.pid) {
			this.getList();
		}
	},
	methods: {
		toClose(){
			this.show = false;
		},
		toShow(){
			this.show = true;
		},
		onClosed(){
			this.addForm.tip_time = '';
			this.addForm.id = '';
			this.addForm.state = 1
		},
		toEdit(id){
			let _this = this;
			this.$http.request({
				url: '/ProgramTip/detail',
				datas: {id: id},
				success(res) {
					_this.addForm.state = res.state;
					_this.addForm.tip_time = res.tip_time;
					_this.addForm.id = id;
					_this.show = true;
				}
			});
		},
		toDel(id){
			let _this = this;
			this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$http.request({
					url: '/ProgramTip/del',
					datas: {id: id},
					success(res) {
						_this.$utils.success("删除成功！");
						_this.getList();
					}
				});
			}).catch(() => {});
		},
		getList(){
			let _this = this;
			this.$http.request({
				url:'/ProgramTip/listPage',
				datas:this.searchForm,
				success(res){
					_this.list = res.list;
					_this.totalCount = res.totalCount;
					_this.totalPage = res.totalPage;
				}
			})
		},
		toSubmit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let _url = '';
					if(_this.addForm.id){
						_url = '/ProgramTip/edit';
					}else{
						_url = '/ProgramTip/add';
					}
					_this.$http.request({
						url:_url,
						datas:_this.addForm,
						success(res){
							_this.$message.success('添加成功');
							_this.getList();
							_this.toClose();
						}
					})
				}
			});
		}
	}
}
</script>
